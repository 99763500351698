import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"

import { VueReCaptcha } from 'vue-recaptcha-v3'

// @ts-ignore


import "./assets/styles/tailwind.css"
import "./assets/icons/rendered/icons.css"
import "./assets/styles/style.sass"



createApp(App)
	.use(store)
	.use(router)
	.use(VueReCaptcha, { siteKey: '6LcesdAbAAAAAKRNXhElSxCI2_U4sRshGtIwG6W_' })
	.mount("#app")
