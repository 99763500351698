
import { Options, Vue } from "vue-class-component"
// import { State, Action, Getter } from "vuex-class";
import axios from "axios"

@Options({})
export default class Contacter extends Vue {
  capToken: string = "";

  $recaptchaLoaded: any;
  $recaptcha: any;
  successfulSent: boolean = false;
  response: string = "";

  // async mounted() {}

  async getCaptchaToken() {
  	await this.$recaptchaLoaded()
  	const token = await this.$recaptcha("login")
  	this.capToken = token
  }

  async sendForm() {
  	await this.getCaptchaToken()
  	const form: any = this.$refs.rsform
  	form.querySelector('input[name="g-recaptcha-response"]').value =
      this.capToken
  	var data = new FormData(form)
  	let request: any = new XMLHttpRequest()
  	request.onreadystatechange = () => {
  		console.log(request)
  		if (request.readyState <= 3) return
  		this.response = request.responseText
  		if (request.status == 200) {
  			form.reset()
  			this.successfulSent = true
  		}else{
  			this.response = "Oh, das ist wohl was schief gegangen. Probiere es bitte später wieder, wir versuchen solange den Fehler zu beheben."
  		}
  	}
  	request.open(form.method, form.action)
  	request.send(data)
  }
}
