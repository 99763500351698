
import { Options, Vue } from "vue-class-component"
import { TweenLite, Elastic, Bounce } from "gsap"
import gsap from "gsap"
// import { State, Action, Getter } from "vuex-class";
// @ts-ignore
//- import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion"

@Options({
	components: {
		//- BadgerAccordion,
		//- BadgerAccordionItem,
	},
})
export default class FAQ extends Vue {
  contents: any[] = [
  	{
  		title: "WAS IST DAS ROCKSTUDIO IN GRAZ?",
  		description:
        "Das Rockstudio ist DAS Tonstudio in Österreich für Rock, Pop und Metal. Wir machen keine Kompromisse bei Sound, Mix und Mastering. Wir produzieren für die Charts und für die führenden Bands aus ganz Österreich ... und die, die es noch werden wollen. Unser Studio ist ein Ort an dem Musik verewigt wird und Geschichte geschrieben wird.",
  		active: false,
  	},
  	{
  		title: "DAS TONSTUDIO FÜR ROCK, POP & METAL. IST DAS ALLES?",
  		description:
        "Ja das ist alles. Kein Schlager, keine Elektro, keine Kompromisse, keinen Scheißdreck.",
  		active: false,
  	},
  	{
  		title: "WAS KOSTET EINE AUFNAHME?",
  		description:
        'Das ist pauschal schwer zu beantworten. Wir können dir auch nicht sagen was ein "Auto" generell kostet, denn es kommt darauf an ob du einen VW Käfer oder einen Porsche fahren willst. Aber wir finden bei 85% aller Bands den perfekten Weg zwischen Hammer-Sound und passendem Budget! Sprechen wir einfach über dein Projekt!',
  		active: false,
  	},
  	{
  		title: "MACHT IHR AUCH NUR MIXING & MASTERING?",
  		description:
        "Ja, bei manchen Produktionen übernehmen wir auch nur Mix und/oder Mastering. Bitte sprecht das jedoch vorab mit uns ab.",
  		active: false,
  	},
  	{
  		title: "WIE LÄUFT EINE ZUSAMMENARBEIT AB?",
  		description:
        "Jede Zusammenarbeit fängt mit einem Kennenlernen an. Du/Ihr kommt zu uns ins Studio. Wir hören uns einige deiner/eurer Songs oder Konzepte an, wir zeigen dir das Studio und einige Produktionen. Dann besprechen wir, wo ihr gerade steht und wie die nächsten Schritte aussehen. Das Erstgespräch ist kostenlos.",
  		active: false,
  	},
  ];

  expand(e: any, i: any) {
  	const el: any = this.$refs["accordion-body-" + i]
  	console.log(el)
  	if (this.contents[i].active === false) {
  		this.contents[i].active = true

  		gsap.to(el, {
  			duration: 0.35,
  			height: el.scrollHeight,
  			ease: "bounce.out",
  		})
  		// TweenLite.to(el, 0.35, {
  		// 	height: el.scrollHeight,
  		// 	// ease: Bounce.easeOut,
  		// 	ease: Elastic.easeOut.config(1, 0.4),
  		// 	// ease: Elastic.easeOut,
  		// })
  	} else {
  		this.contents[i].active = false
  		gsap.to(el, {
  			duration: 0.35,
  			height: 0,
  			ease: "bounce.out",
  		})
  		// TweenLite.to(el, 0.35, {
  		// 	height: 0,
  		// 	ease: Bounce.easeOut,
  		// 	// ease: Elastic.easeOut.config(1, 0.8),
  		// 	// ease: Elastic.easeOut,
  		// })
  	}
  }
}
