
import { Options, Vue } from "vue-class-component"
// import { State, Action, Getter } from "vuex-class";

@Options({})
export default class HomeGallery extends Vue {
  images: any[] = [
  	{
  		thumb: "/img/rockstudio_drumset_rock_tonstudio.jpg",
  		src: "/img/rockstudio_drumset_rock_tonstudio.jpg",
  	},
  	{
  		thumb: "/img/rockstudio_metal_rock_tonstudio.jpg",
  		src: "/img/rockstudio_metal_rock_tonstudio.jpg",
  	},
  	{
  		thumb: "/img/rockstudio_rock_metal_tonstudio.jpg",
  		src: "/img/rockstudio_rock_metal_tonstudio.jpg",
  	},
  	{
  		thumb: "/img/rockstudio_tonstudio_metal_oesterreich.jpg",
  		src: "/img/rockstudio_tonstudio_metal_oesterreich.jpg",
  	},
  	{
  		thumb: "/img/rockstudio-pop_metal_tonstudio.jpg",
  		src: "/img/rockstudio-pop_metal_tonstudio.jpg",
  	},
  	{
  		thumb: "/img/tonstudio_rock_metal_österreich.jpg",
  		src: "/img/tonstudio_rock_metal_österreich.jpg",
  	},
  	{
  		thumb: "/img/tonstudio-rockstudio-graz.jpg",
  		src: "/img/tonstudio-rockstudio-graz.jpg",
  	},
  ];
  currentImageId: number = 0;
  sliterInterval: any = null;
  mounted() {
  	this.makeSliderInterval
  }
  makeSliderInterval() {
  	clearInterval(this.sliterInterval)
  	this.sliterInterval = setInterval(() => {
  		this.nextImage()
  	}, 6000)
  }

  nextImage() {
  	if (this.currentImageId == this.images.length - 1) this.currentImageId = 0
  	else this.currentImageId++
  }

  get currentImage() {
  	this.makeSliderInterval()
  	return this.images[this.currentImageId]
  }

  get mainImage() {
  	return { "background-image": `url('${this.currentImage.src}')` }
  }
}
