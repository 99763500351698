
import { Options, Vue } from "vue-class-component"
// import { State, Action, Getter } from "vuex-class";
import HomeGallery from "@/components/HomeGallery.vue"
import SoundCloud from "@/components/SoundCloud.vue"
import Contacter from "@/components/Contacter.vue"
import FAQ from "@/components/FAQ.vue"

@Options({
	components: { HomeGallery, SoundCloud, Contacter, FAQ },
})
export default class Home extends Vue {}
